<template>
  <ListPageContainer :title="$t('announce.routes')">
    <div class="filters">
      <ItemsListFilter v-if="currentTagsSet.length > 0"
                       :current-filters="currentTagsSet"
                       :active-filter="activeCategoryIndex"
                       @filter="filterByCategory"
      />
    </div>

    <r-row class="px-4" v-if="currentDataSet.length > 0 && !loading">
      <r-col v-for="item in currentDataSet" :key="item.id" :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <LazyAnnouncement :data="item" @goto="goToItem(item)" />
      </r-col>
    </r-row>

    <NothingFound v-else-if="currentDataSet.length === 0 && !loading"/>

    <ListLoading v-else/>
  </ListPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import NothingFound from "@/components/Miscellaneous/NothingFound";
import ListLoading from "@/components/Miscellaneous/ListLoading";
import ItemsListFilter from "@/components/Filters/ItemsListFilter";
import ListPageContainer from "@/components/Containers/ListPageContainer";
import LazyAnnouncement from "@/components/LazyAnnouncement/LazyAnnouncement";

export default {
  name: "RoutesListView",

  components: {
    LazyAnnouncement,
    ListPageContainer,
    NothingFound,
    ListLoading,
    ItemsListFilter
  },

  computed: {
    ...mapGetters(['GET_ROUTES', 'GET_ROUTES_CATEGORIES', 'GET_FILTERED_ROUTES', 'GET_SINGLE_CITY']),

    currentDataSet() {
      if (this.isFiltered) {
        return this.GET_FILTERED_ROUTES;
      }
      return this.GET_ROUTES;
    },

    currentTagsSet() {
      return this.$makeCategoryTagsSet(this.GET_ROUTES_CATEGORIES);
    },

    singleCityId() {
      return {
        cityId: this.GET_SINGLE_CITY ? this.GET_SINGLE_CITY[0].id : ''
      }
    }
  },

  data() {
    return {
      isFiltered: null,
      loading: false,

      activeCategoryIndex: 0,
      filterCategoryId: null,
    }
  },

  methods: {
    filterByCategory({id, index}) {
      if (this.activeCategoryIndex === index) return;

      this.loading = true;
      this.activeCategoryIndex = index;
      this.filterCategoryId = id;

      if (id === 'all') {
        this.filterCategoryId = null;
      }

      this.$store.dispatch('GET_FILTERED_ROUTES_FROM_SERVER',
          {
            category: this.filterCategoryId,
            cityId: this.singleCityId?.cityId
          }).finally(() => {
        this.loading = false;
        this.isFiltered = true;
      });
    },

    goToItem(item) {
      this.$router.push({ path: `/routes/${item.id}` });
    },
  }
}
</script>

<style lang="scss">
.filters {
  margin-bottom: 24px;
}
</style>
